import request from '@/config/request.js'
import setting from '@/config/settings'
const ApiEvaluation = request.FileDowm
import common from '@/components/common/'
import pdf from 'vue-pdf'
export default {
  components: {
    ...common,
    pdf
  },
  name: 'upload-list',
  data () {
    return {
      fileDownLoadUrl: setting.gbs.fileDownLoad,
      appUrl: setting.gbs.host,
      qiniu: setting.gbs.qiniu,
      search_data: {
        filters: [{
          field: 'fileName',
          operator: 'like',
          value: ''
        }, {
          field: 'isEnable',
          operator: 'eq',
          value: true
        }, {
          field: 'fileDirectory',
          operator: 'eq',
          value: ''
        }, {
          field: 'del',
          operator: 'eq',
          value: false
        }],
        current_page: 1,
        total: 0,
        page_size: 10,
      },
      tableList: [],
      status: [
        {
          name: '已使用',
          value: true
        },
        {
          name: '未使用',
          value: false
        }
      ],
      myHeaders: { Authorization: this.$store.state.login.token },
      formLabelWidth: '120px',
      uploadDailog: false,
      uploadDetails: {
        paperName: '',
        categoryPath: '',
        paperUrl: '',
        categoryPath2: '',
        size: '',
        suffix: ''
      },
      rules: {
        uploadUrl: [
          { required: true, message: '请上传文件', trigger: 'change' }
        ]
      },
      fileList: [],
      treeData: [
        {
          directoryId: '-1',
          directoryName: '全部资料',
          children: []
        }
      ],
      defaultShowNodes: ['-1'],
      defaultProps: {
        label: 'title',
        isLeaf: 'isLeaf'
      },
      checkNodes: [],
      treeDailog: false,
      treeEditDailog: false,
      treeEditFrom: {
        directoryId: '',
        directoryName: ''
      },
      treeNodeFrom: {
        parentId: '',
        directoryName: ''
      },
      rows: [],
      uploadList: [],
      btnLoading: false,
      totalFiles: 0,
      successfulUploads: 0,
      failedUploads: 0,
      uploading: [],
      pdfUrl: '',
      pdfView: false,
      pageNum: 1,
      pageTotalNum: 1,
      loadedRatio: 0,
      imgView: false,
      imgUrl: '',
      downLoading: false,
      fileDownId: '',
      textToCopy: ''
    }
  },
  // 默认认展开一级
  // watch: {
  //   treeData: {
  //     handler() {
  //       this.treeData.forEach((item) => {
  //         this.defaultShowNodes.push(item.directoryId);
  //       });
  //     },
  //     deep: true,
  //   },
  // },
  computed: {
    tableHight: function () {
      return window.innerHeight - 330
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    loadNode(node, resolve) {
      // resolve([])
      if (node.level === 0) {
        ApiEvaluation.getTree.call(this, {}, (listData) => {
          return resolve(listData);
        })
      } else {
        ApiEvaluation.getChildFileDirectoryTreeItems.call(this, {directoryId: node.data.key}, (listData) => {
          return resolve(listData);
        })
      }

      /*setTimeout(() => {
        const data = [{
          name: 'leaf',
          leaf: true
        }, {
          name: 'zone'
        }];

        resolve(data);
      }, 500);*/
    },
    closePdfView () {
      this.pdfUrl = ''
      this.pdfView = false
    },
    getView (row) {
      const url = this.qiniu + decodeURI(encodeURI(row.fileUrl))
      this.downUrl = url
      if (/\.(doc|docx|ppt|pptx|xls|xlsx)$/.test(url)) {
        window.open(
          'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url), '_blank'
        )
        return false
      }  else if (/\.(jpg|jpge|png)$/.test(url)) {
        this.imgUrl = url
        this.imgView = true
      } else if (/\.(pdf)$/.test(url)) {
        this.pdfUrl = url
        console.log(url, 'pdf')
        this.pdfView = true
      } else {
        window.open(url)
      }
    },
    async getCopy (row) {
      const url = this.qiniu + row.fileUrl
      /*try {
        await navigator.clipboard.writeText(url+ '?' +  row.fileName);
        this.$message.success('复制成功！');
        ApiEvaluation.addFileDownloadNum.call(this, row, (listData) => {
          // this.initData()
        })
      } catch (error) {
        this.$message.error('复制失败：' + error);
      }*/
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.value = url+ '?' +  row.fileName;
      input.focus();
      input.select();
      try {
        const result = document.execCommand('copy');
        document.body.removeChild(input);
        if (!result) {
          console.error('复制失败');
        } else {
          this.$message.success('复制成功');
        }
      } catch (e) {
        document.body.removeChild(input);
        this.$message.error('当前浏览器不支持复制功能，请检查更新或更换其他浏览器操作');
      }

    },
    getDown (row) {
      const url = this.qiniu + row.fileUrl
      window.open(url + '?attname=' +  encodeURIComponent(row.fileName) + '.' + row.fileFormat)
      ApiEvaluation.addFileDownloadNum.call(this, row, (listData) => {
        this.initData()
      })
      /*let link = document.createElement('a')
      let url = this.qiniu + decodeURI(encodeURI(row.fileUrl))*/
      // 这里是将url转成blob地址，
      /*const elink = document.createElement("a");
      elink.download = row.fileName;
      elink.style.display = "none";
      elink.href = 'https://angelbell.anqinyun.com/fmp/ZSXSK/20220428/313/%E3%80%8A%E6%AF%8D%E4%BA%B2%E8%8A%82%E6%B4%BB%E5%8A%A8%E6%B5%B7%E6%8A%A5%E3%80%8B.zip'
      // URL.createObjectURL(blob);
      elink.setAttribute('download', row.fileName + '.' + row.fileFormat);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);*/
      /*fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        if ("download" in document.createElement("a")) {
          const elink = document.createElement("a");
          elink.download = row.fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob)
          // URL.createObjectURL(blob);
          elink.setAttribute('download', row.fileName + '.' + row.fileFormat);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        } else {
          navigator.msSaveBlob(blob, row.fileName);
        }
        // let url = window.URL.createObjectURL(blob);
        // window.open(url, '_blank');



        /!*link.href = URL.createObjectURL(blob)
        console.log(link.href)
        // link.download = ''
        link.setAttribute('download', row.fileName + '.' + row.fileFormat);
        document.body.appendChild(link)
        link.click()*!/
        ApiEvaluation.addFileDownloadNum.call(this, row, (listData) => {
          this.initData()
        })
      })*/
     /* const url = this.qiniu + decodeURI(encodeURI(row.fileUrl));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', row.fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);*/
      // link.download = row.fileName + '.' + row.fileFormat;

      // 发起 fetch 请求，获取文件内容
      /*fetch(url)
        .then(response => response.blob())
        .then(blob => {
          // 创建临时链接
          link.href = URL.createObjectURL(blob);
          link.setAttribute('download', row.fileName);
          document.body.appendChild(link);
          // 触发点击事件下载文件
          link.click();
          document.body.removeChild(link);
          this.$message({
            type: 'success',
            message: '下载成功，请等待!'
          })

          ApiEvaluation.addFileDownloadNum.call(this, row, (listData) => {
            this.initData()
          })
         /!* this.downLoading = false
          this.fileDownId = ''*!/
          // 释放临时链接
          URL.revokeObjectURL(link.href);
        })
        .catch(error => {
          // this.downLoading = false
          // this.fileDownId = ''
          console.error('文件下载出错:', error);
        });*/
    },
    getFileType (row) {
      const url = this.qiniu + decodeURI(encodeURI(row.fileUrl))
      const verifyFile = /.(doc|docx|ppt|pptx|xls|xlsx|jpg|jpge|png|pdf)$/i
      if (verifyFile.test(url)) {
        return true
      } else {
        return false
      }
    },
    // 上一页
    prePage () {
      let page = this.pageNum
      page = page > 1 ? page - 1 : this.pageTotalNum
      this.pageNum = page
    },
    // 下一页
    nextPage () {
      let page = this.pageNum
      page = page < this.pageTotalNum ? page + 1 : 1
      this.pageNum = page
    },
    initData () {
      var data = {
        offset: (this.search_data.current_page - 1) * this.search_data.page_size,
        limit: this.search_data.page_size,
        data: {},
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'uploadTime'
      }
      data = Object.assign(data, ({
        filters: this.search_data.filters.filter(item => {
          if (item.value !== '') {
            return item
          }
        })
      } || {}))
      ApiEvaluation.pageFileDirectoryDetails.call(this, data, (listData) => {
        this.tableList = listData.content
        this.search_data.total = parseInt(listData.totalElements)
      })
    },
    handleSelectionChange (val) {
      this.rows = val
    },
    onDeleteFiles () {
      if (this.rows.length > 0) {
        this.$confirm('确定删除选中的文件？ 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let arr = []
          this.rows.map(item => {
            arr = [item.fileId, ...arr]
          })
          ApiEvaluation.batchUpdateFileDel.call(this, {
            del: true, fileids: arr
          }, res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.onSearch()
          })
        })
      } else {
        this.$message({
          type: 'error',
          message: '请勾选需要删除的文件!'
        })
      }
    },
    onDeleteFile (row) {
      this.$confirm('确定删除该文件？ 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const arr = [row.fileId]
        ApiEvaluation.batchUpdateFileDel.call(this, {
          del: true, fileids: arr
        }, res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.onSearch()
        })
      })
    },
    deleteFileDirectory (data) {
      this.$confirm('确定删除选中的文件目录？ 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiEvaluation.deleteFileDirectory.call(this, { directoryId: data.directoryId }, res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          // this.getTree()
        })
      })
    },
    getNode (data) {
      this.checkNodes = data
      this.search_data.filters[2].value = data.key !== '-1' ? data.key : ''
      this.initData()
    },
    append (data) {
      this.treeNodeFrom.parentId = data.directoryId ? data.directoryId : ''
      this.treeDailog = true
    },
    onEdit (data) {
      this.treeEditFrom.directoryId = data.directoryId
      this.treeEditFrom.directoryName = data.directoryName
      this.treeEditDailog = true
    },
    onCancelTree (str, formName) {
      this.$refs[formName].resetFields()
      this.$data[str] = false
    },
    onCancel (formName) {
      this.$refs[formName].resetFields()
      this.fileList = []
      this.uploadDetails = {
        paperName: '',
        categoryPath: '',
        paperUrl: '',
        categoryPath2: '',
        size: '',
        suffix: ''
      }
    },
    setEditShow (row) {
      ApiEvaluation.getExamPaper.call(this, {
        paperId: row.paperId
      }, (res) => {
        const path = (res.categoryPath.split(',')).map(i => {
          return parseInt(i)
        })
        this.uploadDetails = {
          paperName: res.paperName,
          categoryPath: path[0],
          categoryPath2: path[1],
          paperUrl: res.paperUrl,
          paperId: res.paperId
        }
        this.$nextTick(() => {
          ApiEvaluation.getTopCategory.call(this, {}, (listData) => {
            this.categoryTable = listData
            ApiEvaluation.getCategoryByTypeId.call(this, { typeId: path[0] }, (listData) => {
              this.papersDailog = true
            })
          })
        })
      })
    },
    handleChange (file, fileList) {
    },
    /*handleAvatarError(err, res, file) {
      // 更新 fileList，标记上传失败的文件
      const errorFile = {
        ...file,
        status: 'error', // 设置文件状态为错误
      };
      this.fileList.push(errorFile);

      // 检查是否所有文件都已上传完成
      if (this.isAllFilesUploaded()) {
        console.log('All files uploaded (with errors)');
        // 执行其他操作，如刷新表单
        this.initData();
      }
    },*/
    /*isAllFilesUploaded() {
      // 检查是否所有文件都已上传完成
      return this.fileList.filter(file => file.status !== 'uploading').length === this.fileList.length;
    },*/
    isAllFilesUploaded() {
      return this.fileList.every(file => file.status === 'success');
    },
    handleAvatarError (err, res, file) {
      this.btnLoading = false
      console.log(err, res, file)
    },
    handleAvatarSuccess (res, file) {
      this.uploadList = [res, ...this.uploadList]
      if (this.uploadList.length === this.uploading.length) {
        let arr = []
        this.uploadList.filter(item => {
          if (!item.uploadErrorMessage) {
            arr = [item[0], ...arr]
          }
        })
        ApiEvaluation.saveFileDirectoryDetails.call(this, { fileDirectoryId: this.checkNodes.key, fileList: arr }, (listData) => {
          this.$message({
            type: 'success',
            message: '上传成功!'
          })
          this.initData()
          this.uploadDailog = false
          this.uploading = []
          this.uploadList = []
        })


      }
    },
    async handleAvatarSuccess1(res, file) {
      if (!res[0].uploadErrorMessage) {
        await ApiEvaluation.saveFileDirectoryDetails.call(this, { fileDirectoryId: this.checkNodes.key, fileList: res }, (listData) => {
          // 更新 fileList，标记上传成功的文件
          const successFile = {
            ...file,
            response: res, // 将上传成功的响应信息保存在 response 属性中
            status: 'success', // 设置文件状态为成功
          };
          this.fileList.push(successFile);
          // 检查是否所有文件都已上传完成
          if (this.isAllFilesUploaded()) {
            // 执行其他操作，如刷新表单
            this.uploadDailog = false
            this.fileList = []
            this.initData();
          }
        });
      } else {
        // 更新 fileList，标记上传失败的文件
        const errorFile = {
          ...file,
          status: 'error', // 设置文件状态为错误
        };
        this.fileList.push(errorFile);
        // 检查是否所有文件都已上传完成
        if (this.isAllFilesUploaded()) {
          // 执行其他操作，如刷新表单
          this.uploadDailog = false
          this.fileList = []
          this.initData();
        }
      }
    },
    beforeAvatarUpload (file) {
      console.log("beforeAvatarUpload", 1)
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20MB!');
      }
      if (isLt20M) {
        this.uploading.push(file)
      }
      return isLt20M
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    async onSubmit(formName) {
      const that = this
      await that.onUploadSubmit();
    },
    async onUploadSubmit() {
      await this.$refs.upload.submit();
    },
    saveData() {
      // 调用保存接口的逻辑
      // ...
    },
    onSubmitTreeNodeName (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiEvaluation.addFileDirectory.call(this, this.treeNodeFrom, () => {
            this.$refs[formName].resetFields()
            // this.getTree()
            this.treeDailog = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onSubmitTreeEditName (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiEvaluation.editFileDirectory.call(this, this.treeEditFrom, () => {
            this.$refs[formName].resetFields()
            // this.getTree()
            this.treeEditDailog = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    oldonSubmit () {
      this.$refs.upload.submit()
      /*this.onUploadSubmit().then(() => {
        console.log(1, this.fileList.length);
        // 在这里可以继续处理其他逻辑
      })*/

    },

    onShowUploadDailog () {
      if (this.checkNodes.length === 0 || !this.checkNodes.key) {
        this.$message({
          type: 'error',
          message: '请先选择根目录以下的子目录!'
        })
      } else {
        this.uploadDailog = true
      }
    },
    setStatus (row) {
      this.$confirm('确定' + (row.isEnable ? '关闭' : '开启') + '该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiEvaluation.updateExamPaperStatus.call(this, { fileId: row.fileId, isEnable: (row.isEnable ? false : true) }, res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.initData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    delPaper (id) {
      this.$confirm('确定删除该记录？ 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiEvaluation.recyclePaper.call(this, { paperId: id }, res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.initData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    setNew (id) {
      this.$confirm('确定把该条记录设置为最新, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiEvaluation.stickPaper.call(this, { paperId: id }, res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.initData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    resetTable () {
      this.search_data.filters[0].value = ''
      this.initData()
    },
    /**
     * 搜索事件
     */
    onSearch () {
      this.search_data.current_page = 1
      this.initData()
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.search_data.current_page = page
      this.initData()
    },
    getTree () {
      ApiEvaluation.getTree.call(this, {}, (listData) => {
        this.treeData[0].children = listData
      })
    }
  },
  mounted () {
    // this.getTree()
    this.initData()
  }
}
