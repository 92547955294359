import request from '@/config/request.js';
import setting from '@/config/settings';
import moment from 'moment';
const DoubleTeacher = request.DoubleTeacher;

class TableCell {
    constructor(posX, posY, id, lessonCover, coverUrl, courseId, courseName, lessonId, lessonName, status) {
        this.posX = posX;
        this.posY = posY;
        this.id = id;
        this.lessonCover = lessonCover;
        this.coverUrl = coverUrl;
        this.courseId = courseId;
        this.courseName = courseName;
        this.lessonId = lessonId;
        this.lessonName = lessonName;
        this.status = status;
    }
}

class TimetableWeek {
    constructor() {
        this.cells = [];
        let index = 0;
        for (let y = 0; y < 8; y++) {
            for (let x = 0; x < 7; x++) {
                this.cells[index] = new TableCell(x, y);
                index++;
            }
        }
    }

    getCell(posX, posY) {
        for (const cell of this.cells) {
            if (cell.posX === posX && cell.posY === posY) {
                return cell;
            }
        }
        return null;
    }
}

class LessonSelection {
    constructor(srcPosX, srcPosY, id, courseId, courseName, lessonCover, coverUrl, lessonId, lessonName, status, serialNum) {
        this.srcPosX = srcPosX;
        this.srcPosY = srcPosY;
        this.id = id;
        this.courseId = courseId;
        this.courseName = courseName;
        this.coverUrl = coverUrl;
        this.lessonCover = lessonCover;
        this.lessonId = lessonId;
        this.lessonName = lessonName;
        this.status = status;
        this.serialNum = serialNum;
    }
}
export default {
    name: 'time-table-week',
    data() {
        return {
            timetableWeek: null,
            curTimetable: null,
            classTimetableData: null,
            modalSelLessonVisible: false,
            curLessonSelection: new LessonSelection(),
            tagTypeList: [],
            tagList: [],
            courseCategory: "1",
            tagTypeCategory: "",
            tagCategory: "",
            weekNums: [0, 1, 2, 3, 4, 5, 6, 7],
            weekDays: [0, 1, 2, 3, 4, 5, 6],
            courseLeftList: [],
            courseIndex: 0,
            clickCourseStatus: 0,
            selectedRowKey: null,
            curSelectCourseId: 0,
            courseRightList: [],
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '张小刚',
                address: '上海市普陀区金沙江路 1517 弄'
            }, // 更多数据...
            ],
            multipleSelection: []
        }
    },
    updated() {
    },
    computed: {
        tableHight: function () {
            return window.innerHeight - 255;
        },
        loading: function () {
            return this.$store.state.global.ajax_loading;
        }
    },
    methods: {
        showModalSelLesson(event) {
            // 实现模态框的显示逻辑
            if (!this.classTimetableData) {
                this.$message({
                    type: 'warning',
                    message: '数据不存在'
                });
                return;
            }

            const eTarget = event.currentTarget;
            const posX = eTarget.getElementsByClassName('posX')[0].innerHTML;
            const posY = eTarget.getElementsByClassName('posY')[0].innerHTML;
            this.curLessonSelection.srcPosX = parseInt(posX);
            this.curLessonSelection.srcPosY = parseInt(posY);

            this.tabChange(1);
            this.statusStudy = 1;

            this.modalSelLessonVisible = true;
            this.courseRightData = [];
            this.selectedRowKey = null;


        },
        onRemoveCourse(event) {
            // 实现移除课程的逻辑
            const eTarget = event.currentTarget;
            const posXY = eTarget.getElementsByClassName('posXY')[0].innerHTML;
            this.$confirm('要清除当前排的课吗？', '提示', {
                confirmButtonText: '确定',
                type: 'warning'
            }).then(() => {
                const xy = posXY.split(',');
                const cell = this.curTimetable.getCell(Number(xy[0]), Number(xy[1]));

                DoubleTeacher.deleteLesson.call(this, cell.id + "/" + this.timetableWeek.id, res => {
                    if (res && res.success) {
                        this.getTimetableClassDetails();
                    }
                });
            }).catch(() => {
            });
        },
        tabChange(key) {
            if (Number(key) === 1) {
                DoubleTeacher.getTagtypeList.call(this, {}, res => {
                    if (res && res.success) {
                        const newData = res.data.filter(item => item.code !== 'KCB');
                        this.tagTypeCategory = newData[0].id;
                        this.tagTypeList = newData;
                        this.tagTypeTabChange(newData[0].id);
                    }
                });
            }
        },
        tagTypeTabChange() {
            DoubleTeacher.getTagList.call(this, { typeId: this.tagTypeCategory, status: "NORMAL" }, res => {
                if (res && res.success) {
                    this.tagList = res.data;
                    this.tagCategory = res.data[0].id;
                    this.tagTabChange();
                }
            });
        },
        tagTabChange() {
            DoubleTeacher.getProductList.call(this, {
                tagId: this.tagCategory,
                courseType: 'COURSE',
                term: this.timetableWeek.term
            }, res => {
                if (res && res.success) {
                    if (!res.data || res.data.length === 0) {
                        return;
                    }
                    const data = res.data;
                    const newData = data.map(item => item.course);
                    this.courseLeftList = newData;

                    this.curLessonSelection.courseId = newData[0].courseId || newData[0].id;
                    this.curLessonSelection.courseName = newData[0].name;
                    this.curLessonSelection.courseTitle = newData[0].title;
                    this.courseIndex = 0;

                    DoubleTeacher.getLessonList.call(this, data[0].courseId, response => {
                        if (response && response.success) {
                            this.courseRightList = response.data;
                        }
                    });
                }
            });
        },
        getTimetableClassDetails() {
            DoubleTeacher.getTimetableClassDetails.call(this, this.timetableWeek.id, res => {
                if (res && res.success) {
                    this.classTimetableData = res.data;
                    this.setDataValue(res.data);
                }
            });
        },
        onClickCourse(item, index) {
            this.curLessonSelection.courseId = item.courseId || item.id;
            this.curLessonSelection.courseName = item.name;
            this.curLessonSelection.courseTitle = item.title;
            this.courseIndex = index;
            //  根据课程id去请求右边课列表
            DoubleTeacher.getLessonList.call(this, item.courseId || item.id, response => {
                if (response && response.success) {
                    this.courseRightList = response.data;
                }
            });
        },
        confirmModalSelLesson() {
            if (!this.curLessonSelection) {
                this.$message({
                    type: 'warning',
                    message: '请选择课程'
                });
                return;
            } else if (!this.curLessonSelection.lessonName || !this.selectedRowKey) {
                this.$message({
                    type: 'warning',
                    message: '请选择课程'
                });
                return;
            } else {
                const { lessonId, lessonName, courseId, courseTitle, lessonCode, srcPosX, srcPosY } = this.curLessonSelection;
                const cell = this.curTimetable.getCell(srcPosX, srcPosY);
                const data = {
                    id: cell.id,
                    lessonId,
                    lessonName,
                    courseId: courseId,
                    courseName: courseTitle,
                    status: 0,
                    lessonCode,
                    type: 0,
                };
                DoubleTeacher.putTimetableLesson.call(this, data, res => {
                    if (res && res.success) {
                        this.getTimetableClassDetails();
                        this.modalSelLessonVisible = false;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }

        },
        handleSelectionChange(val) {
            if (val.length === 1) {
                this.curLessonSelection.lessonId = val[0].id;
                this.curLessonSelection.lessonName = val[0].name;
                this.curLessonSelection.lessonCode = val[0].code;
            }
            // 如果val是数组且长度大于1，说明用户尝试选择了多行
            // 这里我们将其强制转为单选模式
            if (val.length > 1) {
                // 假设每行数据都有一个唯一的id属性
                this.$refs.multipleTable.clearSelection(); // 先清除所有选中项
                this.$refs.multipleTable.toggleRowSelection(val[val.length - 1], true); // 然后选中最后一个被选中的项
            }
            // 更新selectedRowKey，这里假设val是一个包含行对象的数组
            this.selectedRowKey = val.length ? val[0].id : null; // 或者其他唯一标识符
        },
        selectable(row, index) {
            // 这里可以根据需要控制哪些行可以被选中
            // 例如，如果selectedRowKey已经存在且不等于当前行的key，则返回false以禁止选中
            // 但在这个简单的例子中，我们总是允许选中
            return true;
            // 或者更复杂的逻辑：
            // return this.selectedRowKey !== row.id;
        },
        handleClose(done) {
            done();
        },
        setDataValue(classTimetableData) {

            const { monA1, monA2, monA3, monA4, monA5, monA6, monA7, monA8 } = classTimetableData;
            const { tueA1, tueA2, tueA3, tueA4, tueA5, tueA6, tueA7, tueA8 } = classTimetableData;
            const { wedA1, wedA2, wedA3, wedA4, wedA5, wedA6, wedA7, wedA8 } = classTimetableData;
            const { thurA1, thurA2, thurA3, thurA4, thurA5, thurA6, thurA7, thurA8 } = classTimetableData;
            const { friA1, friA2, friA3, friA4, friA5, friA6, friA7, friA8 } = classTimetableData;
            const { satA1, satA2, satA3, satA4, satA5, satA6, satA7, satA8 } = classTimetableData;
            const { sunA1, sunA2, sunA3, sunA4, sunA5, sunA6, sunA7, sunA8 } = classTimetableData;

            for (let i = 0; i < 7; i++) {
                for (let j = 0; j < 8; j++) {

                    const cell = this.curTimetable.getCell(i, j);
                    let data = null;
                    if (i === 0) {
                        if (j === 0) {
                            data = monA1;
                        }
                        else if (j === 1) {
                            data = monA2;
                        }
                        else if (j === 2) {
                            data = monA3;
                        }
                        else if (j === 3) {
                            data = monA4;
                        }
                        else if (j === 4) {
                            data = monA5;
                        }
                        else if (j === 5) {
                            data = monA6;
                        }
                        else if (j === 6) {
                            data = monA7;
                        }
                        else if (j === 7) {
                            data = monA8;
                        }
                    }
                    else if (i === 1) {
                        if (j === 0) {
                            data = tueA1;
                        }
                        else if (j === 1) {
                            data = tueA2;
                        }
                        else if (j === 2) {
                            data = tueA3;
                        }
                        else if (j === 3) {
                            data = tueA4;
                        }
                        else if (j === 4) {
                            data = tueA5;
                        }
                        else if (j === 5) {
                            data = tueA6;
                        }
                        else if (j === 6) {
                            data = tueA7;
                        }
                        else if (j === 7) {
                            data = tueA8;
                        }
                    }
                    else if (i === 2) {
                        if (j === 0) {
                            data = wedA1;
                        }
                        else if (j === 1) {
                            data = wedA2;
                        }
                        else if (j === 2) {
                            data = wedA3;
                        }
                        else if (j === 3) {
                            data = wedA4;
                        }
                        else if (j === 4) {
                            data = wedA5;
                        }
                        else if (j === 5) {
                            data = wedA6;
                        }
                        else if (j === 6) {
                            data = wedA7;
                        }
                        else if (j === 7) {
                            data = wedA8;
                        }
                    }
                    else if (i === 3) {
                        if (j === 0) {
                            data = thurA1;
                        }
                        else if (j === 1) {
                            data = thurA2;
                        }
                        else if (j === 2) {
                            data = thurA3;
                        }
                        else if (j === 3) {
                            data = thurA4;
                        }
                        else if (j === 4) {
                            data = thurA5;
                        }
                        else if (j === 5) {
                            data = thurA6;
                        }
                        else if (j === 6) {
                            data = thurA7;
                        }
                        else if (j === 7) {
                            data = thurA8;
                        }
                    }
                    else if (i === 4) {
                        if (j === 0) {
                            data = friA1;
                        }
                        else if (j === 1) {
                            data = friA2;
                        }
                        else if (j === 2) {
                            data = friA3;
                        }
                        else if (j === 3) {
                            data = friA4;
                        }
                        else if (j === 4) {
                            data = friA5;
                        }
                        else if (j === 5) {
                            data = friA6;
                        }
                        else if (j === 6) {
                            data = friA7;
                        }
                        else if (j === 7) {
                            data = friA8;
                        }
                    }
                    else if (i === 5) {
                        if (j === 0) {
                            data = satA1;
                        }
                        else if (j === 1) {
                            data = satA2;
                        }
                        else if (j === 2) {
                            data = satA3;
                        }
                        else if (j === 3) {
                            data = satA4;
                        }
                        else if (j === 4) {
                            data = satA5;
                        }
                        else if (j === 5) {
                            data = satA6;
                        }
                        else if (j === 6) {
                            data = satA7;
                        }
                        else if (j === 7) {
                            data = satA8;
                        }
                    }
                    else if (i === 6) {
                        if (j === 0) {
                            data = sunA1;
                        }
                        else if (j === 1) {
                            data = sunA2;
                        }
                        else if (j === 2) {
                            data = sunA3;
                        }
                        else if (j === 3) {
                            data = sunA4;
                        }
                        else if (j === 4) {
                            data = sunA5;
                        }
                        else if (j === 5) {
                            data = sunA6;
                        }
                        else if (j === 6) {
                            data = sunA7;
                        }
                        else if (j === 7) {
                            data = sunA8;
                        }
                    }
                    if (cell.status != 1 && data != undefined) { // 如果不等于1会重新初始化
                        cell.id = data.id;
                        cell.courseId = data.courseId;
                        cell.courseName = data.courseName;
                        cell.lessonId = data.lessonId;
                        cell.lessonName = data.lessonName;
                        cell.lessonCode = data.lessonCode;
                        cell.coverUrl = data.coverUrl;
                        cell.type = data.type;

                        if (data.coverUrl != null) {
                            const url = data.coverUrl;
                            if (data.coverUrl == '' || data.coverUrl == null) {
                                // url = require('../../../../assets/cover_lesson_default.png');
                            }
                            const coverStyle = {
                                position: 'relative',
                                backgroundImage: `url(\"${url}\")`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            };
                            cell.lessonCover = coverStyle;
                        }
                        else {
                            cell.lessonCover = null;
                        }

                    }
                    if (data != undefined) {// 无数据，都为空。
                        cell.id = data.id;
                        cell.courseId = data.courseId;
                        cell.courseName = data.courseName;
                        cell.lessonId = data.lessonId;
                        cell.lessonName = data.lessonName;
                        cell.coverUrl = data.coverUrl;
                        cell.lessonCover = null;
                        cell.type = data.type;
                    }
                }
            }
        }
    },
    created() {
        this.curTimetable = new TimetableWeek();
        this.timetableWeek = this.$store.state.timetableWeek;
    },
    mounted() {
        this.getTimetableClassDetails();
    }
}
