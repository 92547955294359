<template>
  <el-card shadow="never" class="portal-eChart-box">
    <div  class="portal-common-title">
      <span>{{ title }}</span>
    </div>
    <div class="e-box-body-join">
      <div class="item" @click="goToUrl('/education/teacher-management')">
        <img src="@/assets/home/add-teacher.png">
        <span>添加老师</span>
      </div>
      <div class="item" @click="goToUrl('/education/class-management')">
        <img src="@/assets/home/add-class.png">
        <span>添加班级</span>
      </div>
      <div class="item" @click="goToUrl('/file/fileDownload')">
        <img src="@/assets/home/down.png">
        <span>资料下载</span>
      </div>
      <div class="item" @click="goToUrlOther('http://study.angelbell.cn')">
        <img src="@/assets/home/anqinyun.png">
        <span>安亲成长云课堂</span>
      </div>
      <div class="item" @click="goToUrlOther('https://cms.anqinyun.com')">
        <img src="@/assets/home/shuangshi.png">
        <span>全能双师管理端</span>
      </div>
      <div class="item" @click="goToUrlOther('http://xqd.maxai.com.cn/#/login')">
        <img src="@/assets/home/maxai.png">
        <span>MaxAi管理后台</span>
      </div>


    </div>
  </el-card>
</template>
<script>
import request from '@/config/request.js'
const ApiHome = request.Home
export default {
  props: {
    title: { type: String, default: '' },
    option: { type: Object, default: () => {} },
    propsApi: '',
    subTitle: '',
    isSearch:  { type: Boolean, default: true },
  },
  data() {
    return {
      chart: null,
      currOption: {},
      isEmpty: true,
      activeName: 'regionChart',
      options: [
        {
          value: '1',
          label: '本周'
        },
        {
          value: '2',
          label: '本月'
        },
        {
          value: '3',
          label: '本年'
        }
      ],
      value: '1'
    }
  },
  created() {

  },
  methods: {
    onDown (url) {
      window.open(url)
    },
    goToUrlOther (url) {
      window.open(url)
    },
    goToUrl (url) {
      this.$router.push({
        path: url
      })
    },
  }
}
</script>

<style lang="less">
.el-card__body{
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  .portal-common-title{
    padding: 18px 20px;
    box-sizing: border-box;
  }
  .e-box-body-join {
    flex: 1;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    padding-bottom: 10px;
    .item{
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      margin: 10px 15px;
      width: 100px;
      img{
        width: 60px;
        height: 60px;
        border-radius: 10px;
      }
      span{
        color: #454040;
        font-size: 14px;
        margin-top: 14px;
      }
    }
  }
}


</style>
