import HacHeader from './HacHeader/Index.vue'
import HacLeftMenu from './HacLeftMenu/Index.vue'
import BreadCrumb from './BreadCrumb/Index.vue'
import Tabs from './Tabs/Index.vue'
import SelectMultiple from './SelectMultiple/Index.vue'
import TreeMultiple from './TreeMultiple/Index.vue'
import Pagination from './Pagination/index.vue'
import HPieRegionChart from './HPieRegionChart/index.vue'
import HLineRegionChart from './HLineRegionChart/index.vue'
import HShortcut from './HShortcut/index.vue'
import HPieRoseRegionChart from './HPieRoseRegionChart/index.vue'
import HPieStudentClueChart from './HPieStudentClueChart/index.vue'
export default {
  HacHeader,
  HacLeftMenu,
  BreadCrumb,
  Tabs,
  SelectMultiple,
  TreeMultiple,
  Pagination,
  HPieRegionChart,
  HLineRegionChart,
  HShortcut,
  HPieRoseRegionChart,
  HPieStudentClueChart
}
