import request from '@/config/request.js'
import setting from '@/config/settings'
const ApiCommodity = request.Mall.Commodity
export default {
  name: 'mall-commodity',
  data () {
    const checkInt = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入正确的数值'))
      } else {
        var reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/
        if (reg.test(value) === false) {
          callback(new Error('数值输入有误'))
        } else {
          callback()
        }
      }
    }
    return {
      loadtext: '正在加载中',
      myHeaders: { Authorization: this.$store.state.login.token },
      qiniu: setting.gbs.qiniu,
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      teacherSignState: [
        {
          label: '正常',
          value: 'NORMAL'
        }, {
          label: '异常',
          value: 'ABNORMAL'
        }
      ],
      signOnType: [
        {
          label: '考勤机',
          value: 'ATTENDANCE_MACHINE'
        }, {
          label: '手工',
          value: 'MANUAL'
        }
      ],
      exportDailog: false,
      exportTime: {
        startDate: '',
        endDate: '',
        ajaxType: 'form'
      },
      exportRules: {
        startDate: [
          {
            required: true,
            message: '请选择开始时间',
            trigger: 'change'
          }
        ],
        endDate: [
          {
            required: true,
            message: '请选择结束时间',
            trigger: 'change'
          }
        ]
      },
      productRules: {
        productName: [
          { required: true, message: '请输入商品名称', trigger: 'blur' }
        ],
        score: [
          { required: true, validator: checkInt, trigger: 'blur' }
        ],
        stock: [
          { required: true, validator: checkInt, trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入商品描述', trigger: 'blur' }
        ]
      },
      formLabelWidth: '120px',
      shopDailog: false,
      shopDetails: {
        productName: '',
        productImg: '',
        description: '',
        stock: 0,
        score: 0,
        status: '',
        remark: ''
      },
      imageUrl: '',
      shopStatus: [
        {
          label: '上架',
          value: 2
        }, {
          label: '下架',
          value: 1
        }
      ],
      isEdit: 'add',
      search_data: {
        filters: [{
          field: 'zoneStudent.student.studentName',
          operator: 'like',
          value: ''
        }, {
          field: 'attendDate',
          operator: 'ge',
          value: ''
        }, {
          field: 'morningSignState',
          operator: 'eq',
          value: ''
        }]
      },
      urlHost: setting.gbs.host,
      showDelete: [], // 控制删除图标显示
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    },
    imageUrls() {
      if (this.imageUrl === "") {
        return []
      } else {
        return this.imageUrl.split(';');
      }
    },
    isUploadDisabled() {
      return this.imageUrls.length > 2;
    }
  },
  watch: {
    // 监听imageUrls的变化，以更新showDelete数组
    imageUrl: {
      immediate: true,
      handler() {
        this.showDelete = this.imageUrl.split(';').map(() => false);
      }
    }
  },
  methods: {
    preventClick () {
      console.log(123)
    },
    onAdd () {
      this.isEdit = 'add'
      this.shopDailog = true
      this.shopDetails = {
        productName: '',
        productImg: '',
        description: '',
        stock: 0,
        score: 0,
        status: '',
        remark: ''
      }
    },
    onEdit (row) {
      this.isEdit = 'edit'
      this.shopDailog = true
      this.shopDetails = Object.assign({}, row)
      this.imageUrl = row.productImg ? row.productImg : ''
     /* this.isEdit = 'edit'
      this.shopDailog = true
      this.shopDetails = Object.assign({}, row)
      if (typeof row.productImg ===  'object') {
        this.imageUrl = row.productImg.map(item => {
          return this.qiniu + item
        })
      } else {
        this.imageUrl = [this.qiniu + row.productImg]
      }
      this.imageUrlName = row.productImg*/
    },
    onCancel (formName) {
      this.$refs[formName].resetFields()
      this.imageUrl = ''
    },
    onDelete (row) {
      this.$confirm('确认删除该商品吗?删除数据不可恢复，是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          productId: row.productId
        }
        ApiCommodity.deleteScoreProduct.call(this, data, () => {
          this.getList()
        })
      }).catch(() => {
      })
    },
    handleRemove (file) {
      let i = 0
      this.imageUrl.map((item, index) => {
        if (item.name === file.name) {
          i = index
        }
      })
      this.imageUrl.splice(i, 1)
    },
    showDeleteIcon(index) {
      this.$set(this.showDelete, index, true);
    },
    hideDeleteIcon(index) {
      this.$set(this.showDelete, index, false);
    },
    deleteImage(item, index) {
      // 从 imageUrls 中移除对应的图片 URL
      this.imageUrls.splice(index, 1);
      this.updateImageUrl(); // 更新 imageUrl 字符串
      // 同时更新 showDelete 数组
      this.showDelete.splice(index, 1);
      // 如果 imageUrls 为空，确保 showDelete 也为空
      if (this.imageUrls.length === 0) {
        this.showDelete = [];
      }
    },
    updateImageUrl() {
      // 更新 imageUrl 字符串
      this.imageUrl = this.imageUrls.join(';');
      // 如果 imageUrls 为空，确保 imageUrl 为空字符串
      if (this.imageUrls.length === 0) {
        this.imageUrl = '';
      }
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 张图片，本次选择了 ${files.length} 张，共选择了 ${files.length + fileList.length} 张`);
    },
    handleAvatarSuccess (res, file) {
      if (this.imageUrl === '') {
        this.imageUrl = res.result
      } else {
        this.imageUrl += (";" + res.result)
      }
    },
    beforeAvatarUpload (file) {
      // 检查文件类型和大小
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return (isJPG || isPNG) && isLt2M;
    },
    onSave (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.shopDetails.productImg = this.imageUrl
          ApiCommodity[this.isEdit === 'edit' ? 'updateScoreProduct' : 'addProduct'].call(this, that.shopDetails, res => {
            that.$message({
              message: '保存成功',
              type: 'success'
            })
            that.getList()
            that.$refs[formName].resetFields()
            that.shopDailog = false
            that.imageUrl = ''
          })
        }
      })
    },
    onExport (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiCommodity.exportProduct.call(this, this.exportTime, (res) => {
            window.open(res.result)
          })
        }
      })
    },
    /**
     * 搜索事件
     */
    onSearch () {
      const newQuery = {} // 深拷贝
      this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value) {
          where.push(this.search_data.filters[s])
        }
      }
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    getImg (str, row) {
      if (!str) {
        return ''
      }
      let html = ''
      let arr = str.split(';')
      arr.map(item => {
        html += "<img  src=" + this.qiniu + item + "  style='border-radius: 10px;width: 80px;height: 80px;object-fit: cover'  fit='contain' />"
      })
      return html
    },
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      // const time = setInterval(this.onLoading, 500)
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: null,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'productId'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      } else {
        var queryArr = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value) {
            queryArr.push(this.search_data.filters[s])
          }
        }
        data = Object.assign(data, { filters: queryArr } || {})
      }
      ApiCommodity.getProduct.call(this, data, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    }
  },
  mounted () {
    this.getList()
  }
}
