import request from '@/config/request.js'
import setting from '@/config/settings'
const ApiClassManagement = request.ClassManagement
const ApiTeacherManagement = request.TeacherManagement
const regPos = /^\d+(\.\d+)?$/ // 非负浮点数
const regInt = /^\d+?$/ // 非负浮点数
export default {
  name: 'class-management',
  data () {
    var checkTotalClass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入总课次'))
      }
      setTimeout(() => {
        if (!regInt.test(value)) {
          callback(new Error('请输入数字值'))
        } else {
          callback()
        }
      }, 1000)
    }
    var checkClassDegrees = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入学位数'))
      }
      setTimeout(() => {
        if (!regInt.test(value)) {
          callback(new Error('请输入正整数'))
        } else {
          callback()
        }
      }, 1000)
    }
    var checkTotalTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入总课时'))
      }
      setTimeout(() => {
        if (!regInt.test(value)) {
          callback(new Error('请输入数字值'))
        } else {
          callback()
        }
      }, 1000)
    }
    var checktuition = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入学费'))
      }
      setTimeout(() => {
        if (!regPos.test(value)) {
          callback(new Error('请输入正确的值'))
        } else {
          callback()
        }
      }, 1000)
    }
    var checkScheduleType = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('请选择排课方式'))
      } else {
        if (this.calssDetail.dayList.length < 1 && value === 'CLASS_TIME') {
          this.$refs.classManageForm.validateField('dayList')
        }
        callback()
      }
    }
    var checkDayList = (rule, value, callback) => {
      if (value.length < 1 && this.calssDetail.scheduleType === 'CLASS_TIME') {
        callback(new Error('请选择排课时间'))
      } else {
        callback()
      }
    }
    return {
      calssDialogDetails: false,
      qiniu: setting.gbs.qiniu,
      paginationsDialog: {
        current_page: 1,
        total: 0,
        page_size: 5,
        layout: 'prev, pager, next'
      },
      studentOnDialog: false,
      search_data_student: {
        studentRange: 'NOT_JOIN_STUDY_CLASS',
        studentCondition: 'NOT_TO'
      },
      studentOnTable: [],
      studentOnTableItem: [],
      studentOnTableAll: [],
      studentOnTableAllItem: [],
      edit: '',
      search_data: {
        filters: [{
          field: 'class.className',
          operator: 'like',
          value: ''
        }]
      },
      tableList: [],
      studyClassType: 'HOSTING',
      studyClass: [
        {
          name: '托管班',
          value: 'HOSTING'
        },
        {
          name: '素养班',
          value: 'TRAINING'
        }
      ],
      myHeaders: { Authorization: this.$store.state.login.token },
      imageUrl: '',
      imageUrlName: '',
      formLabelWidth: '120px',
      calssDialog: false,
      calssDetail: {
        studyClassType: 'HOSTING',
        studyClassName: null,
        zoneTeacher: {
          checked: false,
          disable: false,
          teacherId: ''
        },
        zoneTeacherIds: [],
        grade: null,
        division: null,
        studyClassImg: null,
        studyClassZoneStudentIds: [],
        dayList: [],
        scheduleType: null,
        startDate: null,
        studyClassTime: null,
        totalClass: null,
        totalTime: null,
        tuition: null,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        classDegrees: undefined
      },
      calssDetailRules: {
        studyClassType: [
          { required: true, message: '请选择班级类型', trigger: 'change' }
        ],
        studyClassName: [
          { required: true, message: '请输入班级名称', trigger: 'blur' }
        ],
        'zoneTeacher.teacherId': [
          { required: true, message: '请选择主教老师', trigger: 'change' }
        ],
        grade: [
          { required: true, message: '请选择年级', trigger: 'change' }
        ],
        scheduleType: [
          { required: true, validator: checkScheduleType, trigger: 'change' }
        ],
        startDate: [
          { required: true, message: '请选择开始日期', trigger: 'change' }
        ],
        studyClassTime: [
          { required: true, message: '请选择上课时间', trigger: 'change' }
        ],
        totalClass: [
          { required: true, validator: checkTotalClass, trigger: 'change' }
        ],
        totalTime: [
          { required: true, validator: checkTotalTime, trigger: 'change' }
        ],
        tuition: [
          { required: true, validator: checktuition, trigger: 'change' }
        ],
        dayList: [
          { type: 'array', required: true, validator: checkDayList, trigger: 'change' }
        ],
        classDegrees: [
          { required: true, validator: checkClassDegrees, trigger: 'change' }
        ]
      },
      teacherTable: [],
      // day: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
      // day: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
      day: [
        {
          name: '周一',
          value: 'monday'
        },
        {
          name: '周二',
          value: 'tuesday'
        },
        {
          name: '周三',
          value: 'wednesday'
        },
        {
          name: '周四',
          value: 'thursday'
        },
        {
          name: '周五',
          value: 'friday'
        },
        {
          name: '周六',
          value: 'saturday'
        },
        {
          name: '周日',
          value: 'sunday'
        }
      ],
      scheduleType: [
        {
          name: '循环',
          value: 'LOOP'
        },
        {
          name: '课次',
          value: 'CLASS_TIME'
        }
      ],
      division: [
        {
          name: '全能标准版',
          value: 'DOUBLE_DIVISION'
        },
        {
          name: '妙成长幼小版',
          value: 'YOUNG_CONNECTION'
        }
      ],
      grade: [
        {
          name: '一年级',
          value: 'FIRST_GRADE'
        },
        {
          name: '二年级',
          value: 'SECOND_GRADE'
        },
        {
          name: '三年级',
          value: 'THIRD_GRADE'
        },
        {
          name: '四年级',
          value: 'FOURTH_GRADE'
        },
        {
          name: '五年级',
          value: 'FIFTH_GRADE'
        },
        {
          name: '六年级',
          value: 'SIXTH_GRADE'
        },
        {
          name: '幼小',
          value: 'YOUNG_CONNECTION'
        },
        {
          name: '其他',
          value: 'OTHER'
        }
      ],
      classImage: [
        {
          name: require('../../../../assets/classImg/d6501.png'),
          value: 'd6501.png'
        }, {
          name: require('../../../../assets/classImg/d6502.png'),
          value: 'd6502.png'
        }, {
          name: require('../../../../assets/classImg/d6503.png'),
          value: 'd6503.png'
        }, {
          name: require('../../../../assets/classImg/d6504.png'),
          value: 'd6504.png'
        }, {
          name: require('../../../../assets/classImg/d6505.png'),
          value: 'd6505.png'
        }, {
          name: require('../../../../assets/classImg/d6506.png'),
          value: 'd6506.png'
        }, {
          name: require('../../../../assets/classImg/d6507.png'),
          value: 'd6507.png'
        }, {
          name: require('../../../../assets/classImg/d6508.png'),
          value: 'd6508.png'
        }, {
          name: require('../../../../assets/classImg/d6509.png'),
          value: 'd6509.png'
        }, {
          name: require('../../../../assets/classImg/d6510.png'),
          value: 'd6510.png'
        }, {
          name: require('../../../../assets/classImg/d6511.png'),
          value: 'd6511.png'
        }, {
          name: require('../../../../assets/classImg/d6512.png'),
          value: 'd6512.png'
        }
      ],
      peixunImage: [
        {
          name: require('../../../../assets/classImg/d7101.png'),
          value: 'd7101.png'
        }, {
          name: require('../../../../assets/classImg/d7102.png'),
          value: 'd7102.png'
        }, {
          name: require('../../../../assets/classImg/d7103.png'),
          value: 'd7103.png'
        }, {
          name: require('../../../../assets/classImg/d7104.png'),
          value: 'd7104.png'
        }, {
          name: require('../../../../assets/classImg/d7105.png'),
          value: 'd7105.png'
        }, {
          name: require('../../../../assets/classImg/d7106.png'),
          value: 'd7106.png'
        }, {
          name: require('../../../../assets/classImg/d7107.png'),
          value: 'd7107.png'
        }, {
          name: require('../../../../assets/classImg/d7108.png'),
          value: 'd7108.png'
        }, {
          name: require('../../../../assets/classImg/d7109.png'),
          value: 'd7109.png'
        }, {
          name: require('../../../../assets/classImg/d7110.png'),
          value: 'd7110.png'
        }, {
          name: require('../../../../assets/classImg/d7111.png'),
          value: 'd7111.png'
        }, {
          name: require('../../../../assets/classImg/d7112.png'),
          value: 'd7112.png'
        }, {
          name: require('../../../../assets/classImg/d7113.png'),
          value: 'd7113.png'
        }, {
          name: require('../../../../assets/classImg/d7114.png'),
          value: 'd7114.png'
        }, {
          name: require('../../../../assets/classImg/d7115.png'),
          value: 'd7115.png'
        }, {
          name: require('../../../../assets/classImg/d7116.png'),
          value: 'd7116.png'
        }
      ],
      studyClassZoneStudentIds: [],
      selectStudyClassZoneStudentIds: [],
      totalElements: 0,
      totalElementsAll: 0,
      tatol: 0,
      totalStuNum: 0
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 215
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    expandVisible(row, rowIndex) {
      let data = row.row
      let res = []
      if (data.studyClass && data.studyClass.studyClassZoneStudents.length > 0) {
        res.push('row-expand-has')
        return res
      } else {
        res.push('row-expand-cover')
        return res
      }
    },
    changeImg () {
      this.imageUrl = ''
      this.imageUrlName = ''
    },
    addStudentOn () {
      const that = this
      var data1 = {
        offset: 0,
        limit: 20000,
        data: {
          studentRange: 'ALL_JOINED_SCHOOL_ZONE',
          studentCondition: 'ALREADY_TO'
        },
        filters: null,
        orFilters: null,
        order: null,
        sort: null
      }
      ApiClassManagement.pageSchoolZoneStudents.call(this, data1, (list) => {
        that.studentOnTableAll = list.content
        that.studentOnDialog = true
        this.$nextTick(() => {
          // this.$refs.multipleTable.clearSelection()
          this.studentOnTableAll.forEach(row => {
            this.studyClassZoneStudentIds.forEach(item => {
              if (item.student.studentId === row.student.studentId) {
                this.$refs.multipleTable.toggleRowSelection(row, true)
              }
            })
          })
        })
      })
    },
    selectChangeStudent (e) {
      this.$nextTick(() => {
        this.studyClassZoneStudentIds = e
      })
    },
    saveOnStudent () {
      this.selectStudyClassZoneStudentIds = this.studyClassZoneStudentIds
      this.calssDetail.studyClassZoneStudentIds = this.selectStudyClassZoneStudentIds.map(item => {
        return item.studentId
      })
      this.studentOnDialog = false
    },
    deleteTag (tag) {
      this.selectStudyClassZoneStudentIds.splice(this.selectStudyClassZoneStudentIds.indexOf(tag), 1)
      this.calssDetail.studyClassZoneStudentIds = this.selectStudyClassZoneStudentIds.map(item => {
        return item.studentId
      })
    },
    selectImg (e) {
      this.imageUrl = require('../../../../assets/classImg/' + e.target.dataset.value)
      this.imageUrlName = e.target.dataset.value
    },
    getFileName (name) { // 获取到文件名
      const pos = name.lastIndexOf('/') // 查找最后一个/的位置
      return name.substring(pos + 1) // 截取最后一个/位置到字符长度，也就是截取文件名
    },
    addClassDialog () {
      this.studyClassZoneStudentIds = []
      this.selectStudyClassZoneStudentIds = []
      this.edit = 'add'
      this.calssDetail = {
        studyClassType: 'HOSTING',
        studyClassName: null,
        zoneTeacher: {
          checked: false,
          disable: false,
          teacherId: ''
        },
        zoneTeacherIds: [],
        grade: null,
        division: null,
        studyClassImg: null,
        studyClassZoneStudentIds: [],
        dayList: [],
        scheduleType: null,
        startDate: null,
        studyClassTime: null,
        totalClass: null,
        totalTime: null,
        tuition: null,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
        classDegrees: undefined
      }
      this.imageUrl = ''
      this.imageUrlName = ''
      this.calssDialog = true
    },
    deleteDetail (row) {
      this.$confirm('确定删除该班级吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiClassManagement.removeStudyClassById.call(this, { studyClassId: row.studyClassId }, () => {
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    editDetail (item) {
      this.edit = 'edit'
      const url = this.getFileName(item.studyClassImg)
      if (url && url.length < 20) {
        try {
          this.imageUrl = require('../../../../assets/classImg/' + url)
          this.imageUrlName = url
        } catch (e) {
          this.imageUrlName = item.studyClassImg
        }
      }
      ApiClassManagement.getStudyClassById.call(this, { studyClassId: item.studyClassId }, (row) => {
        const zoneTeachers = []
        if (row.zoneTeachers && row.zoneTeachers.length > 0) {
          row.zoneTeachers.map(teacher => {
            zoneTeachers.push(teacher.teacherId)
          })
        }
        let stid = []
        if (row.studyClassZoneStudents) {
          stid = row.studyClassZoneStudents.map(i => {
            return i.studentId
          })
        }
        this.calssDetail = {
          studyClassId: row.studyClassId,
          studyClassType: row.studyClassType === '素养班' ? 'TRAINING' : 'HOSTING',
          studyClassName: row.studyClassName,
          zoneTeacher: {
            checked: row.zoneTeacher.checked,
            disable: row.zoneTeacher.disable,
            teacherId: row.zoneTeacher.teacherId,
            teacherName: row.zoneTeacher.teacher.teacherName
          },
          zoneTeacherIds: zoneTeachers,
          grade: row.grade,
          division: row.division,
          studyClassImg: row.studyClassImg,
          studyClassZoneStudentIds: stid,
          dayList: [],
          scheduleType: row.scheduleType,
          startDate: row.startDate,
          studyClassTime: row.studyClassTime,
          totalClass: row.totalClass,
          totalTime: row.totalTime,
          tuition: row.tuition,
          monday: row.monday,
          tuesday: row.tuesday,
          wednesday: row.wednesday,
          thursday: row.thursday,
          friday: row.friday,
          saturday: row.saturday,
          sunday: row.sunday,
          classDegrees: row.classDegrees,
        }
        if (this.day.length > 0) {
          this.day.map(item => {
            if (row[item.value]) {
              this.calssDetail.dayList.push(item.value)
            }
          })
        }
        this.studyClassZoneStudentIds = row.studyClassZoneStudents
        this.selectStudyClassZoneStudentIds = row.studyClassZoneStudents
      })
      this.calssDialog = true
    },
    classDetail (item) {
      this.edit = 'edit'
      const url = item.studyClassImg.indexOf('http') > -1 ? item.studyClassImg: this.getFileName(item.studyClassImg)
      if (url.length < 20) {
        this.imageUrl = require('../../../../assets/classImg/' + this.getFileName(item.studyClassImg))
      } else {
        this.imageUrl = item.studyClassImg.indexOf('http') > -1 ? item.studyClassImg: require('' + url)
      }
      ApiClassManagement.getStudyClassById.call(this, { studyClassId: item.studyClassId }, (row) => {
        const zoneTeachers = []
        if (row.zoneTeachers && row.zoneTeachers.length > 0) {
          row.zoneTeachers.map(teacher => {
            zoneTeachers.push(teacher.teacher.teacherName)
          })
        }
        this.calssDetail = {
          studyClassId: row.studyClassId,
          studyClassType: row.studyClassType === '素养班' ? 'TRAINING' : 'HOSTING',
          studyClassName: row.studyClassName,
          zoneTeacher: {
            checked: row.zoneTeacher.checked,
            disable: row.zoneTeacher.disable,
            teacherId: row.zoneTeacher.teacherId,
            teacherName: row.zoneTeacher.teacher.teacherName,
            position: row.zoneTeacher.position
          },
          zoneTeacherIds: zoneTeachers,
          grade: row.grade,
          division: row.division,
          studyClassImg: row.studyClassImg,
          studyClassZoneStudentIds: row.studyClassZoneStudentIds,
          dayList: [],
          scheduleType: row.scheduleType,
          startDate: row.startDate,
          studyClassTime: row.studyClassTime,
          totalClass: row.totalClass,
          totalTime: row.totalTime,
          tuition: row.tuition,
          monday: row.monday,
          tuesday: row.tuesday,
          wednesday: row.wednesday,
          thursday: row.thursday,
          friday: row.friday,
          saturday: row.saturday,
          sunday: row.sunday,
          classDegrees: row.classDegrees,
        }
        if (this.day.length > 0) {
          this.day.map(item => {
            if (row[item.value]) {
              this.calssDetail.dayList.push(item.value)
            }
          })
        }
        this.studyClassZoneStudentIds = row.studyClassZoneStudents
        this.selectStudyClassZoneStudentIds = row.studyClassZoneStudents
      })
      this.calssDialogDetails = true
    },
    addClass (formName) {
      this.calssDetail.studyClassImg = this.imageUrlName
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.calssDetail.studyClassImg) {
            this.$message('请选择班级图标')
            return
          }
          this.calssDetail.monday = null
          this.calssDetail.tuesday = null
          this.calssDetail.wednesday = null
          this.calssDetail.thursday = null
          this.calssDetail.friday = null
          this.calssDetail.saturday = null
          this.calssDetail.sunday = null
          this.calssDetail.dayList.map(item => {
            this.calssDetail[item] = true
          })
          ApiClassManagement[this.calssDetail.studyClassId ? 'updateClassList' : 'addClassList'].call(this, this.calssDetail, () => {
            this.$refs[formName].resetFields()
            this.calssDetail = {
              studyClassType: 'HOSTING',
              studyClassName: null,
              zoneTeacher: {
                checked: false,
                disable: false,
                teacherId: ''
              },
              zoneTeacherIds: [],
              grade: null,
              division: null,
              studyClassImg: null,
              studyClassZoneStudentIds: [],
              dayList: [],
              scheduleType: null,
              startDate: null,
              studyClassTime: null,
              totalClass: null,
              totalTime: null,
              tuition: null,
              monday: null,
              tuesday: null,
              wednesday: null,
              thursday: null,
              friday: null,
              saturday: null,
              sunday: null,
              classDegrees: undefined
            }
            this.getList()
            this.calssDialog = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.imageUrlName = res.result
    },
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    getDetail (row) {
      this.DetailFormVisible = true
      this.dateDetail = row
    },
    /**
     * 搜索事件
     */
    onSearch () {
      this.getList()
    },
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      var data = {}
      data = Object.assign(data, { studyClassCondition: 'CLASS_MANAGEMENT', studyClassType: this.studyClassType })
      ApiClassManagement.getClassList.call(this, data, (listData) => {
        let list = listData.map(item => {
          if (!item.studyClass.studyClassZoneStudents) {
            item.studyClass['studyClassZoneStudents'] = []
          }
          return item
        })
        this.tableList = list
        this.tatol = 0
        // this.totalStuNum = 0
        this.tableList.map(item => {
          this.tatol += item.studyClass.studyClassZoneStudents.length
          // this.totalStuNum += item.totalStuNum
        })
      })
    },
    getTeacher () {
      var data = {
        offset: 0,
        limit: 20000,
        data: null,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'teacher.accountId'
      }
      ApiTeacherManagement.getTeacherList.call(this, data, (listData) => {
        this.teacherTable = listData.content
      })
    },
    getNotInClassStudent (){
      ApiClassManagement.getZoneStudentDataRemind.call(this, {}, (res) => {
        this.totalStuNum = res.notInClassStudentNum
      })
    }
  },
  mounted () {
    this.getNotInClassStudent()
    this.getList()
    this.getTeacher()
  }
}
