<template>
  <el-card shadow="never" class="portal-eChart-box">
    <div  class="portal-common-title">
      <span>{{ title }}（{{ count  }}人）</span>
      <div style="width: 80px;position: absolute;  top: 13px;    z-index: 10000;   right: 25px;" v-show="isSearch">
        <el-select v-model="value" placeholder="请选择" @change="handleChangeData">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
    </div>
    <div class="eChart-box-body">
      <div ref="regionChart" id="chart" class="dom-canvas" v-show="!isEmpty"></div>
      <div class="portal-common-noData portal-common-noData-eChart" v-show="isEmpty">
        <p class="noData-txt">暂无数据</p>
      </div>
    </div>
  </el-card>
</template>
<script>
import * as echarts from 'echarts'
import resize from '@/components/common/mixins/resize'
import request from '@/config/request.js'
const ApiHome = request.Home
export default {
  mixins: [resize],
  props: {
    title: { type: String, default: '' },
    option: { type: Object, default: () => {} },
    propsApi: '',
    subTitle: '',
    isSearch:  { type: Boolean, default: false },
  },
  data() {
    return {
      chart: null,
      currOption: {},
      isEmpty: true,
      activeName: 'regionChart',
      options: [
        {
          value: '1',
          label: '本周'
        },
        {
          value: '2',
          label: '本月'
        },
        {
          value: '3',
          label: '本年'
        }
      ],
      value: '1',
      count: 0
    }
  },
  created() {
    this.getData()
  },
  mounted () {
  },
  methods: {
    handleChangeData() {
      this.getData()
    },
    timeout(ms) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, ms, 'done')
      })
    },
    getData() {
      ApiHome.getZoneStudentByGrade.call(this, {}, (res => {
        const xAxis = []
        const seriesData = []
        const newSeriesData = []
        let sum = 0
        res.map(item => {
          xAxis.push(item.grade)
          sum += item.studentNum
          seriesData.push(item.studentNum)
          newSeriesData.push(item.newAddStudentNum)
        })
        this.count = sum
        this.currOption = {
          tooltip: {
            trigger: 'axis',
            // show: true, // 控制提示框是否显示
            // trigger: 'item', // 触发类型，默认为 'item'，即鼠标悬停
            // formatter: '{b} : {c}', // 格式化提示框的显示内容
            // textStyle: { // 设置提示框文本的样式
            //   fontSize: 14 // 设置字体大小，从而影响提示框大小
            // }
          },
          legend: {
            data: ['所有学生', '新增学生'], // 图例名称
            orient: 'horizontal', // 设置图例布局方向为水平
            top: 'top', // 设置图例在顶部
            left: 'center' // 设置图例在水平方向上居中
          },
          grid: {
            left: '5%', // 左边距
            right: '5%', // 右边距
            top: '10%', // 顶部边距
            bottom: '8%', // 底部边距
            containLabel: true // 确保坐标轴的标签被包含在 grid 中
          },
          color: [
            '#1aacfd', '#cdcdcd'
          ],
          calculable: true,
          xAxis: [
            {
              type: 'category',
              data: xAxis,
              axisLine: {
                color: '#48b',
                width: 2,
                type: 'solid',
                axisLine: {
                  lineStyle: {
                    color: '#b4b4b4' // 设置X轴颜色
                  }
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '(数量/年级)', // 在Y轴顶部显示单位
              nameLocation: 'end', // 指定单位显示的位置，'end'表示在Y轴的末端，即顶部
              nameTextStyle: {
                // 可以设置单位的样式
                color: '#b4b4b4',
                fontSize: 14
              },
              minInterval: 1,
              splitLine: {
                lineStyle: {
                  type: 'dashed', // 设置网格线为虚线
                  color: '#f3f3f3' // 设置网格线的颜色
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#b4b4b4' // 设置X轴颜色
                }
              }
            }
          ],
          series: [
            {
              name: '所有学生',
              type: 'bar',
              data: seriesData,
              barWidth: '20px',
            },
            {
              name: '新增学生',
              type: 'bar',
              data: newSeriesData,
              barWidth: '20px',
              // itemStyle: {
              //   normal: {
              //     color: function (params) {
              //       var colorList = [
              //         '#B5C334'
              //       ]
              //       return colorList[params.dataIndex]
              //     }
              //   }
              // }
            }
          ]
        }
        this.timeout(100).then(value => {
          this.initChart()
        })
        this.isEmpty = false
        this.resetChart()
      }))
    },
    initChart(refs) {
      let ref = refs ? refs : this.$refs.regionChart
      this.chart =echarts.init(ref)
      this.chart.setOption(this.currOption)
      setTimeout(() => {
        this.$nextTick(() => {
          this.chart.resize()
        })
      }, 50)
    },
    resetChart() {
      this.isEmpty = JSON.stringify(this.currOption) === '{}'
      this.chart && this.chart.dispose()
      this.chart = null
      this.$nextTick(() => {
        if (!this.isEmpty) this.initChart()
      })
    }
  },
  beforeDestroy() {
    if (!this.chart) return
    this.chart.dispose()
    this.chart = null
  }
}
</script>

<style lang="less">
.el-card__body{
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  .portal-common-title{
    padding: 18px 20px;
    box-sizing: border-box;
  }
  .eChart-box-body {
    flex: 1;
    height: 100%;
    .portal-common-noData-eChart{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .dom-canvas{
    height: 100%;
  }
}


</style>
