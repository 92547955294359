<template>
  <el-card shadow="never" class="portal-eChart-box">
    <div  class="portal-common-title">
      <span>{{ title }}（{{ count  }}元）</span>
      <div style="width: 80px;position: absolute;  top: 13px;    z-index: 10000;   right: 25px;" v-show="isSearch">
        <el-select v-model="params.statisticQueryType" placeholder="请选择" @change="handleChangeData">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
    </div>
    <div class="eChart-box-body">
      <div ref="regionChart" id="chart" class="dom-canvas" v-show="!isEmpty"></div>
      <div class="portal-common-noData portal-common-noData-eChart" v-show="isEmpty">
        <p class="noData-txt">暂无数据</p>
      </div>
    </div>
  </el-card>
</template>
<script>
import * as echarts from 'echarts'
import resize from '@/components/common/mixins/resize'
import request from '@/config/request.js'
const ApiHome = request.Home
export default {
  mixins: [resize],
  props: {
    title: { type: String, default: '' },
    option: { type: Object, default: () => {} },
    propsApi: '',
    subTitle: '',
    isSearch:  { type: Boolean, default: false },
  },
  data() {
    return {
      chart: null,
      currOption: {},
      isEmpty: true,
      params: {
        statisticQueryType: 'WEEK'
      },
      options: [
        {
          value: 'WEEK',
          label: '本周'
        },
        {
          value: 'MONTH',
          label: '本月'
        }
      ],
      count: 0
    }
  },
  created() {
    this.getData()
  },
  methods: {
    handleChangeData() {
      this.getData()
    },
    timeout(ms) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, ms, 'done')
      })
    },
    getData() {
      ApiHome.getZoneTempLate.call(this, {apiUrl: this.propsApi, data: this.params}, (list => {
        let sum = 0
        let data = []
        if (typeof list === 'object' && JSON.stringify(list) !== '{}') {
          if (Array.isArray(list)) {
            data =list.map(item => {
              sum += item.value
              return {value: item.value, name: item.label}
            })
          } else {
            for (const [key, value] of Object.entries(list)) {
              sum += value
              data.push({value: value, name: key})
            }
          }
        }
        this.count = sum
        if (data === null || !data || data.length === 0) {
          this.isEmpty = true
          this.currOption = {}
        } else {
          this.currOption = {
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'horizontal', // 图例布局朝向为水平
              bottom: '5%', // 图例位于底部
            },
            series: [
              {
                type: 'pie',
                radius: '55%',
                center: ['50%', '50%'], // 调整饼图的上下左右位置
                data: data,
                roseType: 'area', // 设置为南丁格尔玫瑰图
                hoverAnimation: false, // 设置为 false 以默认高亮显示
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                label: {
                  normal: {
                    show: true,
                    position: 'outside', // 标签显示位置
                    formatter: '{b}: {c}' // 自定义标签内容，显示名称、数值和百分比
                  }
                },
                color: ['#1aacfd', '#71eae0', '#5470c6', '#91cc75', '#fac858', '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
              }
            ]
          };
        }
        this.resetChart()
      }))
    },
    initChart(refs) {
      let ref = refs ? refs : this.$refs.regionChart
      this.chart =echarts.init(ref)
      this.chart.setOption(this.currOption)
      setTimeout(() => {
        this.$nextTick(() => {
          this.chart.resize()
        })
      }, 50)
    },
    resetChart() {
      this.isEmpty = JSON.stringify(this.currOption) === '{}'
      this.chart && this.chart.dispose()
      this.chart = null
      this.$nextTick(() => {
        if (!this.isEmpty) this.initChart()
      })
    }
  },
  beforeDestroy() {
    if (!this.chart) return
    this.chart.dispose()
    this.chart = null
  }
}
</script>

<style lang="less">
.el-card__body{
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  .portal-common-title{
    padding: 18px 20px;
    box-sizing: border-box;
  }
  .eChart-box-body {
    flex: 1;
    height: 100%;
    .portal-common-noData-eChart{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .dom-canvas{
    height: 100%;
  }
}


</style>
