import request from '@/config/request.js'
import setting from '@/config/settings'
const DoubleTeacher = request.DoubleTeacher;
export default {
    name: 'time-table',
    data() {
        return {
            lingjiaoCurrentUser: this.$store.state.login.lingjiaoCurrentUser ? JSON.parse(this.$store.state.login.lingjiaoCurrentUser) : null,
            curClassData: null,
            termList: [],
            classTimetableList: null
        }
    },
    updated() {
    },
    computed: {
        tableHight: function () {
            return window.innerHeight - 255;
        },
        loading: function () {
            return this.$store.state.global.ajax_loading;
        }
    },
    methods: {
        getClassTimetableList() {
            DoubleTeacher.getClassTimetableList.call(this, {
                classRoomId: this.curClassData.id,
                classType: this.curClassData.classType,
                termYear: this.curClassData.termYear,
                term: this.curClassData.term
            }, res => {
                if (res && res.success) {
                    this.classTimetableList = res.data;

                    if (!this.classTimetableList || this.classTimetableList.length === 0) {
                        let classList = [{
                            term: this.curClassData.term,
                            termYear: this.curClassData.termYear,
                            classTypeCustomName: this.curClassData.classTypeCustomName,
                            timeTableCount: 0,
                            belong: 0,
                            classType: this.curClassData.classType,
                        }];
                        this.classTimetableList = classList
                    }
                }
            });
        },
        getAllTermList() {
            DoubleTeacher.getAllTermList.call(this, { merchantId: this.lingjiaoCurrentUser.merchantId }, res => {
                if (res && res.success) {
                    this.termList = res.data;
                }
            });
        },
        jumpToWeek(item) {
            item.classRoomId = this.curClassData.id;
            item.className = this.curClassData.name;
            // this.$router.push({
            //     path: '/double-teacher/classManagement/timeTablePeriod',
            //     query: {
            //         currentItem: item,
            //     }
            // });

            this.$store.commit('setTimeTablePeriod', item);
            this.$router.push({ name: 'classManagement/timeTablePeriod' });
        },
        createTimetable(item) {
            this.$confirm('要一键生成当前班级的学期课程表吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const payloadmes = {
                    classRoomId: this.curClassData.id,
                    termYear: item.termYear,
                    classType: item.classType,
                    term: item.term,
                };

                if (this.lingjiaoCurrentUser && this.lingjiaoCurrentUser.timetableTemplate == 1) {
                    payloadmes.timetableTemplateCode = item.timetableTemplateCode;
                }
                DoubleTeacher.createClassTermTimetable.call(this, payloadmes, res => {
                    if (res && res.success) {
                        this.$message({
                            message: '添加课成功',
                            type: 'success'
                        });
                        this.getClassTimetableList();
                    } else {
                        if (res.code === 700) {
                            this.$message.error('课程表已存在！');
                        } else {
                            this.$message.error(res.message);
                        }
                    }
                });
            }).catch(() => {
            });
        },
        getTermName(key) {
            const arry = {};
            this.termList.map(item => {
                const { name, term } = item;
                const key = term;
                const value = name;
                arry[key] = value;
            });
            return arry[key];
        },
        getClassType(key) {
            const merchantClassTypeMap = this.lingjiaoCurrentUser ? this.lingjiaoCurrentUser.merchantClassTypeMap : null
            if (merchantClassTypeMap != null && this.isEmptyObject(merchantClassTypeMap) == false) {
                if (merchantClassTypeMap[key] == undefined) {
                    let initData = {};
                    initData.name = '未知';
                    initData.customName = '未知';
                    return initData;
                }
                return merchantClassTypeMap[key];
            } else {
                let initData = {};
                initData.name = '未知';
                initData.customName = '未知';
                return initData;
            }
        },
        isEmptyObject(obj) {
            for (const key in obj) {
                return false;
            }
            return true;
        }
    },
    created() {
        this.curClassData = this.$store.state.curClassData;
    },
    mounted() {
        this.getAllTermList();
        this.getClassTimetableList();
    }
}
