import request from '@/config/request.js'
import VueGridLayout from 'vue-grid-layout'
import common from '@/components/common/'
const ApiHome = request.Home
export default {
  name: 'home-index',
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    ...common
  },
  data () {
    return {
      screenWidth: document.body.clientWidth,
      myStudent: '',
      myTeacher: '',
      layout: []
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 305
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  watch: {
    screenWidth () {
      this.myTeacher.resize()
      this.myStudent.resize()
    }
  },
  methods: {
    onDown (url) {
      window.open(url)
    },
    goToUrlOther (url) {
      window.open(url)
    },
    goToUrl (url) {
      this.$router.push({
        path: url
      })
    },
    drawPie () {
      const that = this
      ApiHome.getZoneTeacherByPosition.call(this, {}, (list) => {
        let sum = 0
        const data = list.map(item => {
          sum += item.teacherNum
          return { value: item.teacherNum, name: item.position }
        })
        // 绘制图表
        this.myTeacher.setOption({
          title: {
            text: '在校员工情况(总人数：' + sum + ')',
            left: 'center',
            top: 'bottom',
            triggerEvent: true
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'right',
            top: 'middle'
          },
          series: [
            {
              left: '0',
              name: '员工人数',
              center: ['45%', '50%'],
              type: 'pie',
              radius: ['20%', '50%'],
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ],
          color: [
            '#ff7f50', '#87cefa', '#da70d6', '#32cd32', '#6495ed',
            '#ff69b4', '#ba55d3', '#cd5c5c', '#ffa500', '#40e0d0',
            '#1e90ff', '#ff6347', '#7b68ee', '#00fa9a', '#ffd700',
            '#6b8e23', '#ff00ff', '#3cb371', '#b8860b', '#30e0e0'
          ]
        })
        this.myTeacher.on('click', function (params) {
          if (params.componentType === 'title') {
            that.$router.push({
              path: '/education/teacher-management'
            })
          }
        })
      })
    },
    drawLine () {
      const that = this
      ApiHome.getZoneStudentByGrade.call(this, {}, (list) => {
        const xAxis = []
        const seriesData = []
        let sum = 0
        list.map(item => {
          xAxis.push(item.grade)
          sum += item.studentNum
          seriesData.push(item.studentNum)
        })
        this.myStudent.setOption({
          title: {
            text: '在校学生总人数：' + sum + ' (服务期内)',
            left: 'center',
            top: 'bottom',
            triggerEvent: true,
          },
          xAxis: [
            {
              type: 'category',
              data: xAxis,
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              minInterval: 1,
              type: 'value'
            }
          ],
          series: [
            {
              name: '学生人数',
              type: 'bar',
              barWidth: '60%',
              data: seriesData,
              itemStyle: {
                normal: {
                  color: function (params) {
                    var colorList = [
                      '#B5C334', '#FCCE10', '#E87C25', '#27727B',
                      '#FE8463', '#9BCA63', '#FAD860', '#F3A43B', '#60C0DD'
                    ]
                    return colorList[params.dataIndex]
                  },
                  label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}'
                  }
                }
              }
            }
          ],
          color: [
            '#ff7f50', '#87cefa', '#da70d6', '#32cd32', '#6495ed',
            '#ff69b4', '#ba55d3', '#cd5c5c', '#ffa500', '#40e0d0',
            '#1e90ff', '#ff6347', '#7b68ee', '#00fa9a', '#ffd700',
            '#6b8e23', '#ff00ff', '#3cb371', '#b8860b', '#30e0e0'
          ]
        })
        this.myStudent.on('click', function (params) {
          if (params.componentType === 'title') {
            that.$router.push({
              path: '/education/student-management'
            })
          }
        })
      })
    }
  },
  mounted () {
    console.log('index')
    /*this.myTeacher = this.$echarts.init(document.getElementById('myTeacher'))
    this.myStudent = this.$echarts.init(document.getElementById('myStudent'))
    this.drawPie()
    this.drawLine()
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      })()
    }*/
  }
}
