<template>
  <el-card shadow="never" class="portal-eChart-box">
    <div  class="portal-common-title">
      <span>{{ title }}{{ subTitle }}</span>
      <div style="width: 80px;position: absolute;  top: 13px;    z-index: 10000;   right: 25px;" v-show="isSearch">
        <el-select v-model="params.statisticQueryType" placeholder="请选择" @change="handleChangeData">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
    </div>
    <div class="eChart-box-body">
      <div ref="regionChart" id="chart" class="dom-canvas" v-show="!isEmpty"></div>
      <div class="portal-common-noData portal-common-noData-eChart" v-show="isEmpty">
        <p class="noData-txt">暂无数据</p>
      </div>
      <div class="dom-details" v-show="isDetails">
        <div class="dom-details-item">
          <img src="@/assets/home/img1.png">
          <div>
            <span>新增咨询人数</span>
            <span>{{consultNum}}</span>
          </div>
        </div>
        <div class="dom-details-item">

          <img src="@/assets/home/img1.png">
          <div>
            <span>新增报名人数</span>
            <span>{{enrollNum}}</span>
          </div>
        </div>
        <div class="dom-details-item">
          <img src="@/assets/home/img2.png">
          <div>
            <span>咨询转化率</span>
            <span>{{consultRate}}%</span>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>
import * as echarts from 'echarts'
import resize from '@/components/common/mixins/resize'
import request from '@/config/request.js'
const ApiHome = request.Home
export default {
  mixins: [resize],
  props: {
    title: { type: String, default: '' },
    option: { type: Object, default: () => {} },
    propsApi: '',
    subTitle: '',
    isSearch:  { type: Boolean, default: false },
    isDetails:  { type: Boolean, default: false }
  },
  data() {
    return {
      chart: null,
      currOption: {},
      isEmpty: true,
      activeName: 'regionChart',
      params: {
        statisticQueryType: 'WEEK'
      },
      options: [
        {
          value: 'WEEK',
          label: '本周'
        },
        {
          value: 'MONTH',
          label: '本月'
        }
      ],
      valueOptions: ['门店到访', '转介绍', '地推活动', '招生宝', '新媒体', '其它','扫码','400电话','总部新媒体'],
    consultNum: 0,//新增咨询
    enrollNum: 0,//新增报名
    consultRate: 0,//转换率（已为百分比）
    }
  },
  created() {
    this.getData()
  },
  methods: {
    handleChangeData() {
      this.getData()
    },
    timeout(ms) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, ms, 'done')
      })
    },
    getData() {
      ApiHome.getStudentClueAnalysis.call(this, this.params, (list => {

        const data = list.data
        this.consultNum = list.consultNum//新增咨询
          this.enrollNum= list.enrollNum//新增报名
          this.consultRate= list.consultRate//转换率（已为百分比）
        if (data === null || !data) {
          this.isEmpty = true
          this.currOption = {}
        } else {
          let arr = []
          for (const [key, value] of Object.entries(data)) {
            arr.push({value: value, name: this.valueOptions[key]})
          }
          this.currOption = {
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'horizontal', // 图例布局朝向为水平
              bottom: '5%', // 图例位于底部
            },
            series: [
              {
                type: 'pie',
                radius: ['30%', '50%'],
                center: ['50%', '40%'], // 调整饼图的上下左右位置

                data: arr,
                hoverAnimation: false, // 设置为 false 以默认高亮显示
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                label: {
                  normal: {
                    show: true,
                    position: 'outside', // 标签显示位置
                    formatter: '{b}: {c} ({d}%)' // 自定义标签内容，显示名称、数值和百分比
                  }
                },
                color: ['#1aacfd', '#71eae0', '#5470c6', '#91cc75', '#fac858', '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
              }
            ]
          };
        }
        this.resetChart()
      }))
    },
    initChart(refs) {
      let ref = refs ? refs : this.$refs.regionChart
      this.chart =echarts.init(ref)
      this.chart.setOption(this.currOption)
      setTimeout(() => {
        this.$nextTick(() => {
          this.chart.resize()
        })
      }, 50)
    },
    resetChart() {
      this.isEmpty = JSON.stringify(this.currOption) === '{}'
      this.chart && this.chart.dispose()
      this.chart = null
      this.$nextTick(() => {
        if (!this.isEmpty) this.initChart()
      })
    }
  },
  beforeDestroy() {
    if (!this.chart) return
    this.chart.dispose()
    this.chart = null
  }
}
</script>

<style lang="less">
.el-card__body{
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  .portal-common-title{
    padding: 18px 20px;
    box-sizing: border-box;
  }
  .eChart-box-body {
    display: flex;
    flex: 1;
    height: 100%;
    .dom-canvas{
      flex: 1;
      width: 100%;
      div:nth-child(1){
        width: 100%;
      }
    }
    .dom-details{
      flex-shrink: 0;
      width: 150px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 30px;
      .dom-details-item{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        img{
          width: 47px;
          height: 47px;
        }
        div{
          margin-left: 3px;
          display: flex;
          flex-direction: column;
          flex-shrink: 0;
          width: 100px;

          span{
            display: block;
            font-size: 14px;
            &:nth-child(1) {
              color: #3c3938;
            }
            &:nth-child(2) {
              color: #1a92ff;
            }
          }

        }
      }
    }
    .portal-common-noData-eChart{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .dom-canvas{
    height: 100%;
  }
}


</style>
