import settings from '../config/settings.js'
const login = {
  state: {
    token: localStorage.getItem('bell_admin_token'),
    userInfo: localStorage.getItem('bell_admin_user_info'),
    rootMenu: localStorage.getItem('bell_admin_root_menu'),
    logininfo: {
      loginName: localStorage.getItem('hac_admin_login_name') ? localStorage.getItem('hac_admin_login_name') : '',
      loginPwd: localStorage.getItem('hac_admin_login_pwd') ? localStorage.getItem('hac_admin_login_pwd') : ''
    },
    otoStatus: localStorage.getItem('bell_admin_login_oto') ? localStorage.getItem('bell_admin_login_oto') : '',
    zoneId: localStorage.getItem('bell_admin_login_zoneId') ? localStorage.getItem('bell_admin_login_zoneId') : '',
    rootRouter: localStorage.getItem('bell_admin_root_router') ? localStorage.getItem('bell_admin_root_router') : '',
    isRouterSvae: false,
    schoolsTeacher: localStorage.getItem('bell_admin_root_teacher') ? localStorage.getItem('bell_admin_root_teacher') : [],
    lingjiaoCurrentUser: localStorage.getItem('lingjiao-current-user') ? localStorage.getItem('lingjiao-current-user') : null,
    zoneTeacher: localStorage.getItem('bell_admin_zone_teacher') ? localStorage.getItem('bell_admin_zone_teacher') : null,
  },
  mutations: {
    SET_LOGIN (state, text) {
      localStorage.setItem(settings.gbs.db_prefix + 'token', text)
      state.token = text
    },
    SET_USER (state, text) {
      localStorage.setItem(settings.gbs.db_prefix + 'user_info', text)
      state.userInfo = text
    },
    SET_USER_LOGIN (state, text) {
      localStorage.setItem(settings.gbs.db_prefix + 'login_name', text.loginName)
      localStorage.setItem(settings.gbs.db_prefix + 'login_pwd', text.loginPwd)
    },
    DEL_USER_LOGIN () {
      localStorage.removeItem('bell_admin_login_name')
      localStorage.removeItem('bell_admin_login_pwd')
      localStorage.removeItem('bell_admin_token')
    },
    SET_OTO_STATUS (state, text) {
      state.otoStatus = text
      localStorage.setItem(settings.gbs.db_prefix + 'login_oto', text)
    },
    SET_ROOT_MENU (state, text) {
      state.isRouterSvae = true
      localStorage.setItem(settings.gbs.db_prefix + 'root_menu', text)
      state.rootMenu = text
    },
    SET_ZONE_ID (state, text) {
      state.zoneId = text
      localStorage.setItem(settings.gbs.db_prefix + 'login_zoneId', text)
    },
    SET_ROOT_ROUTER (state, text) {
      state.isRouterSvae = true
      localStorage.setItem(settings.gbs.db_prefix + 'root_router', text)
    },
    SET_SCHOOLS_TEACHER (state, text) {
      state.schoolsTeacher = text
      localStorage.setItem(settings.gbs.db_prefix + 'root_teacher', text)
    },
    SET_ZONE_TEACHER (state, text) {
      state.zoneTeacher = text
      localStorage.setItem(settings.gbs.db_prefix + 'zone_teacher', text)
    },
    SET_LINGJIAO_CURRENT_USER (state, text) {
      state.lingjiaoCurrentUser = text;
      localStorage.setItem('lingjiao-current-user', text);
    },

  },
  actions: {
    resetToken ({ commit }) {
      return new Promise(resolve => {
        window.localStorage.removeItem('bell_admin_token')
        resolve()
      })
    },
    schoolsTeacher (context, text) {
      context.commit('SET_SCHOOLS_TEACHER', text)
    },
    setZoneTeacher (context, text) {
      context.commit('SET_ZONE_TEACHER', text)
    },
    loginIn (context, text) {
      context.commit('SET_LOGIN', text)
    },
    setUserInfo (context, text) {
      context.commit('SET_USER', text)
    },
    setUserLogin (context, text) {
      context.commit('SET_USER_LOGIN', text)
    },
    setRootMenu (context, text) {
      context.commit('SET_ROOT_MENU', text)
    },
    delUserLogin (context) {
      context.commit('DEL_USER_LOGIN')
    },
    setOtoStatus (context, text) {
      context.commit('SET_OTO_STATUS', text)
    },
    setZoneId (context, text) {
      context.commit('SET_ZONE_ID', text)
    },
    setRootRouter (context, text) {
      context.commit('SET_ROOT_ROUTER', text)
    },
    setLingjiaoCurrentUser (context, text) {
      context.commit('SET_LINGJIAO_CURRENT_USER', text)
    },
  }
}
export default login
